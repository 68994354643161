/* You can add global styles to this file, and also import other style files */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap'); */

/* @font-face {
  font-family: "avenir";
  src: url('assets/Avenir_Font/Avenir.ttc') format('truetype'),
  url('assets/Avenir_Font/Avenir_95_Black.ttf') format('opentype'),
  url('assets/Avenir_Font/Avenir_95_Black_Oblique.ttf') format('truetype'),
  url('assets//Avenir_Font/Avenir_Regular.ttf') format('truetype');
  font-weight: bold;
    font-style: normal;
} */

 @font-face {
  font-family: "avenir";
  src: url('assets/Avenir_Font/AvenirLTStd-Black.otf') format('opentype') ;
}
 @font-face {
  font-family: "avenir-reg";
  src: url('assets/Avenir_Font/AvenirLTStd-Book.otf') format('opentype') ;
}
html,
body {
  font-family: "avenir" !important;
  height: 100%;
}

body {
  margin: 0;
  font-family: "avenir" !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "avenir" !important;
}
p,label,td, th,button{
  font-family: "avenir-reg" !important;

}
.point {
  cursor: pointer;
}

.font-blue {
  color: #1B5AA8;
}

.font-green {
  color: #91CC02 !important;
  font-weight: 900;
}

.font-black {
  color: #02050A !important;
}
input::placeholder{
  font-family: "avenir-reg" !important;
}

.modal {
  background: #00000087 !important;
}

.btn-bg {
  background-image: url('./assets/img/Sportsbay_Vendor_Web_Panel_Assets/side_panel_bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.login-input {
  margin-bottom: 10px;
}

.login-input .mdc-text-field--filled {
  height: 40px !important;
  background-color: transparent;
  border-radius: 25px;
  border: 0.5px solid #d4dadd;
}

.login-input .mat-mdc-form-field-infix {
  padding-top: 7px !important;
  padding-bottom: 16px !important;
}

.login-input .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: transparent;
}

.login-input .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: transparent;
}

.login-box .mat-mdc-form-field-focus-overlay {
  background-color: rgb(134 84 207 / 9%);
}

.login-box .mat-mdc-form-field-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.mat-toolbar {
  width: 100% !important;
  /* display: block; */
  /* margin: 1rem 2rem 0rem; */
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  background-image: url(/assets/img/Sportsbay_Vendor_Web_Panel_Assets/header_bg.png);
  background-repeat: no-repeat;
  background-size: initial;
    background-position: right;
}
.login-box{
  height: 80vh;
}

.main-nav {
  position: sticky;
  top: 0;
  z-index: 999;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.main-content {
  padding: 0.5rem 15px;
  background-color: #FDFFF9;
}

.mat-drawer-content {
  background-color: #FDFFF9;
}

.my-check .mdc-checkbox__background {
  width: 14px !important;
  height: 14px !important;
}

.my-check .mdc-checkbox__ripple {
  position: unset !important;
}

.my-check .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #91CC02 !important;
}

.my-check .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: #8BC34A !important;
  background-color: #ffffff !important;
}

.my-check .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #8BC34A !important;
  /* font-size: 25px; */
}

.my-check .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: #8BC34A !important;
  background-color: transparent;
}

.my-check .mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  position: unset !important;
}

.my-check .mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 5px;
  height: 16px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
}

.mat-mdc-form-field-icon-suffix .my-show {
  font-size: 12px;
  margin-bottom: 16px;
  margin-right: 6px;
  color: #91CC02;

}

.profile-pic {
  height: 100%;
  width: 100%;
  background: #fff;
  overflow: hidden;
}

.profile-pic4 img {
  height: 105px;
    width: 100%;;
  /* object-fit: cover; */
}
.location-padding {
  padding-right: 2rem;
}

.location-user {
  font-size: 14px;
}

.upload-ic {
  background-color: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 5px;
  text-align: center;
  line-height: 25px;
  position: absolute;
}

.fileUpload {
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.aside::-webkit-scrollbar {
  display: none !important;
}

.mat-drawer-side {
  border-right: none !important;
}

.mat-toolbar .mat-icon {
  color: #fff !important;
}

/*  */
.with-hover {
  display: none;
}

/* .as-ul li:hover .with-hover{
  display: block;
}
.as-ul li:active .with-hover{
  display: block;
} */
/* .as-ul li:hover .without-hover{
  display: none;
} */
.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 56px;

}

.as-ul .active {
  background-color: #FDFFF9;
  color: #000 !important;
}

.as-ul .active .with-hover {
  display: block !important;
}

.as-ul .active .without-hover {
  display: none !important;
}

.date-choose .mat-mdc-form-field-flex {
  flex-direction: row-reverse !important;
}

/* .mdc-text-field{
  padding:0px 0px !important;
} */
/* language menu */
.lang-menu .mdc-list-item__primary-text {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #000;
}

.lang-menu .mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: 150px I !important;
}

.lang-menu .mat-mdc-menu-content {
  padding: 0px 0px !important;
  width: 145px;
}

.delete .mdc-dialog--open .mdc-dialog__container {
  width: 400px;
  height: 300px;
  left: 50%;
  top: 50%;
  position: absolute;
  background: #000;
  transform: translate(-50%, -50%) !important;
}

.add- {
  padding: 8px 17px;
  border-radius: 50px;
  color: #fff;
  border: 1px solid transparent;
  background-color: #8CC540;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.add-:hover {
  box-shadow: #8cc540 0px 0px 9px;
}

.add- img {
  width: 16px;
  margin-right: 8px;
}

.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}

.ground-canvas .mat-mdc-select .mat-mdc-select-min-line {
  font-size: 12px;
}

.owl-nav .owl-prev {
  position: absolute;
  top: 0px;
  bottom: 0;
  margin: auto 0 !important;
  height: 30px;
  width: 30px;
  left: 10px;
}

.owl-nav .owl-next {
  position: absolute;
  top: -45px;
  ;
  bottom: 0;
  margin: auto 0 !important;
  height: 30px;
  width: 30px;
  right: 10px;
}

.owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}

/* date-nav */
.date-choose .mat-mdc-icon-button.mat-mdc-button-base {
  width: 35px;
  height: 35px;
  padding: 0px;
}

.date-choose .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 9px;
  padding-bottom: 9px;
}

.date-choose .mat-mdc-form-field-infix {
  flex: auto;
  min-width: 0;
  width: 85px;
  position: relative;
  box-sizing: border-box;
}

.date-choose .mat-mdc-form-field-infix {
  min-height: 40px;
}

.date-choose .mdc-text-field {
  padding: 0px 0px !important;
  border-radius: 40px;
  box-shadow: #00BEFF1F 0px 3px 2px;
  background: #fff !important;
}

.owl-theme .owl-nav [class*=owl-] {
  color: #91cc02;
  font-size: 14px;
  margin: 5px;
  padding: 4px 0px;
  font-weight: 800;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 17px;
}

.gf-radio .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--mdc-radio-unselected-icon-color, #2fd23a);
}

.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus)~.mdc-radio__background::before {
  opacity: .04;
  transform: scale(1);
}


.gf-radio .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: #ccc !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #3c8aff !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #3c8aff !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: #ccc !important;
}

/* calendar/css */
.mat-calendar-table-header-divider::after {
  content: "";
  position: absolute;
  top: 0;
  left: -8px;
  right: -8px;
  height: 1px;
  display: none !important;
}

.mat-calendar-table tr:nth-last-child(2) {
  background: #C9E1FF;
  color: #000;
}

@supports (top: max(0%)) {
   .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
        width: max(0px, var(--mdc-shape-small, 0px)) !important;
        border: 1px solid transparent !important;
    }
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  /* border-color: rgba(0, 0, 0, 0.38); */
  border-left: 1px solid gray !important;
  border-radius: 10px !important;
}

.mat-calendar-table-header th {
  padding: 8px 0 8px 0 !important;
  font-weight: 600 !important;
}

.mat-calendar-controls {
  display: flex;
  align-items: center;
  margin: 0px !important;
  background: #1B5AA8;
  color: #fff;
  padding: 4px 0px;
}

.mat-mdc-button:not(:disabled) {
  color: #fff !important;
}

.mat-calendar-arrow {
  fill: #fff !important;
}

.mat-calendar-body-cell-content {
  border-radius: 0px !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: none !important;
  background: #8CC540;
  color: #fff;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  display: none !important;
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-line-ripple::after {
  display: none !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: transparent !important;
}

.mat-datepicker-input {
  font-size: 12px !important;
}

.dash-nav .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.dash-nav .mat-mdc-icon-button svg,
.mat-mdc-icon-button img {
  width: var(--mdc-icon-button-icon-size, 19px) !important;
  height: var(--mdc-icon-button-icon-size, 19px) I !important;
}

.owl-theme .owl-dots .owl-dot.active span {
  background: #8CC540 !important;
  width: 24px !important;
  transition: all 0.3s ease-out;
}

.owl-theme .owl-dots .owl-dot span {
  width: 8px !important;
  height: 8px !important;
  margin: 5px 3px !important;
  background: #AFAFAF;
  display: block;
  transition: opacity .2s ease;
  border-radius: 30px;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 500px !important;
  object-fit: cover !important;
  object-position: center center !important;
}

.iti {
  width: 100%;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text] {
  padding-right: 6px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 25px;
  border: 1px solid #ccc;
}

.iti__selected-flag {
  border-radius: 12px 0px 0px 12px;
}

.mdc-dialog--open .mdc-dialog__container {
  width: 30% !important;
  height: 100% !important;
  right: 0;
  top: 0;
  position: absolute;
  background: #000;
}

/* switch */
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  display: none !important;
}



/* .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: #fff !important;
} */

.mat-mdc-slide-toggle .mat-mdc-slide-toggle-ripple,
.mat-mdc-slide-toggle .mdc-switch__ripple::after {
  position: unset !important;
}
.iti--allow-dropdown input, .iti--allow-dropdown input::placeholder{
  font-family: "avenir-reg" !important;
}

.login-input1 input::placeholder{
  font-size: 12px;
  font-family: "avenir-reg" !important;
}


.login-btn{
  font-family: "avenir-reg" !important;
}
/* ::-webkit-scrollbar{
  display: none;
} */
.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

/* isreal remove */
#iti-item-il {
  display: none;
}

.iti--allow-dropdown input:focus-visible {
  border: 1px solid #ccc !important;
  outline: 0px !important;
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
  font-size: 11px;
}

.scroll {
  /* height: 85vh; */
  overflow-y: scroll;
  box-shadow: #00000029 0px 3px 12px;
  border-radius: 8px;
}


.play-head h3 {
  font-weight: 700;
  font-size: 17px;
  color: #8CC540;
  font-family: 'Montserrat';
  margin-bottom: 0px;
}

.play-head {
  margin-bottom: 1.5rem;
}

/*
.pac-container {
  position: absolute !important;
  z-index: 10455 !important;
} */

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(240, 240, 240, 0.05) !important;
}
.iti__country-list{
  width: 335px !important;
  overflow-x: hidden;
}

.page-size{
  font-size: 16px;
  font-weight: 920;
}
.nav-talks img{
  cursor :pointer !important;
}
.mat-mdc-select-value-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px !important;

}
.required::after {
  content: ' *';
  color: red;

}
.agm-map-container-inner{
  width: 100% !important;
}

.login-input1 input {

  font-size: 12px !important;
  color: #0f0f0f;

}
/* @media (min-width:1200px) and (max-width:1600px){
  .login-text{
    padding: 3rem 3rem !important;
    border-right: 1px solid #d0d9cd;
  }


} */

/* -------------------------------- */
/* responsive */
.login-box{
  position: relative;
}
.login-box:before{
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 0.5px;
  background-color: #33333314;
  left: 50%;
  transform: translate(-50%,0px);
}
.sub-log .login-btn{
  margin-top: 1rem;
}
.login-text{
  height: 100% !important;
}
.login-box .row{
  width: 100% ;
  margin: auto;
  height: 100%;
}






/* .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: #c4c4c4 !important;
} */

.mdc-switch__track::before {
  border-radius: 12px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  width: 10px !important;
  height: 10px !important;
}

.mdc-switch__icons {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  height: 16px !important;
  width: 32px !important;
  border-radius: 12px !important;

  /* border-color: 1px solid green !important; */
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  display: none;
}

.mdc-switch__handle {
  left: 3px !important;
}

/* .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__shadow {

  background: #fff;
} */
.mat-mdc-table .mdc-data-table__row:nth-child(even) {
  background-color: #F9F9F9 !important;

}



/* .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
  width: calc(100% - var(--mdc-switch-handle-width, 24px) ) !important;
} */




/* responsive */
@media (min-width:300px) and (max-width:767px) {

  .login-box {
    width: 85% !important;
  }

  .sports-img {
    display: none !important;
  }

  .login-text {
    padding: 2rem 3rem !important;
  }

  .sale-data {
    width: 140px;
    height: 103px;
    margin-bottom: 1rem;
  }
}




@media (min-width:1400px) and (max-width:1500px){


  .login-box1 {

    height:40vh !important;
}
.text-mid{
  margin-bottom: 10px !important;

}
  .login-input {
    margin-bottom: 15px;
  }
  .login-text{
    padding: 3.5rem 4rem !important;

  }
  .login-input .mdc-text-field--filled {
    height: 50px !important;
  }
  .login-input .mat-mdc-form-field-infix {
    padding-top: 11px !important;
    padding-bottom: 16px !important;
  }
  .login-input input {
    font-size: 16px !important;
    color: #313131;
  }
  .login-input label {
    margin-bottom: 4px;
    font-size: 13px !important;
    color: #818181;
    font-weight: 700;
  }
  .mat-mdc-form-field-icon-suffix .my-show {
    font-size: 14px !important;
    margin-top: 7px !important;
    margin-bottom: 16px !important;
    margin-right: 14px !important;
    color: #91CC02 !important;
  }
  .forget p {
    margin-bottom: 25px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .sign p {
    margin-top: 1rem;
    font-size: 14px !important;
    font-weight: 700;
  }
  .login-text h4 {
    font-size: 25px !important;
    color: #333333;
    font-weight: 700;
    font-family: montserrat;
  }
  .login-text p {
    font-weight: 600;
    font-size: 14px !important;
    /* line-height: 25px !important; */
  }
  .login-input input{
    font-size: 15px !important;
    color: #313131;
  }
  .login-input input::placeholder{
    font-size: 15px !important;
  }
  .login-btn{
    margin-top: 1.75rem !important;
    padding: 12px 28px !important;
    font-size: 14px !important;
  }
  .sports-img {
    width: 75% !important;
    display: block !important;
    margin: 6rem auto !important;
  }


}
@media (min-width:1501px) and (max-width:1600px){

  .view-box ul li   i {
    margin-right: 10px !important;
    /* width: 36px; */
    font-size: 20px !important;
}




  .profile-pic4 img {
    height: 125px !important;
    width: 100% !important;
  }
  .login-box1 {

    height:38vh !important;
}

  .login-text{
    padding: 3rem 3rem !important;

  }
  .login-input .mdc-text-field--filled {
    height: 44px !important;
  }
  .login-input .mat-mdc-form-field-infix {
    padding-top: 9px !important;
    padding-bottom: 16px !important;
  }
  .login-input input {
    font-size: 16px !important;
    color: #313131;
  }
  .login-input label {
    margin-bottom: 4px;
    font-size: 12px !important;
    color: #818181;
    font-weight: 700;
  }
  .mat-mdc-form-field-icon-suffix .my-show {
    font-size: 14px !important;
    margin-top: 3px !important;
    margin-bottom: 16px !important;
    margin-right: 14px !important;
    color: #91CC02 !important;
  }
  .login-input {
    margin-bottom: 15px;
  }
  .iti--separate-dial-code .iti__selected-dial-code {
    margin-left: 6px;
    font-size: 13px;
  }
  .forget p {
    margin-bottom: 25px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .sign p {
    margin-top: 1rem;
    font-size: 14px !important;
    font-weight: 700;
  }
  .login-text h4 {
    font-size: 22px !important;
    color: #333333;
    font-weight: 700;
    font-family: montserrat;
  }
  .login-text p {
    font-weight: 600;
    font-size: 14px !important;
    line-height: 25px !important;
  }
  .login-input input{
    font-size: 15px !important;
    color: #313131;
  }
  .login-input input::placeholder{
    font-size: 15px !important;
  }
  .login-btn{
    margin-top: 1.75rem !important;
    padding: 12px 28px !important;
    font-size: 14px !important;
  }
  .sports-img {
    width: 75% !important;
    display: block !important;
    margin: 4.5rem auto !important;
  }
  /* .inner-content {
    overflow-y: scroll;
    height: 410px !important;
} */

.login-box-admin {
    font-size: 17px !important;
    height: 35vh !important;
}
.ground table tr td {
  font-size: 15px !important;

  font-weight: 400 !important;
}
.day {
  width: 177px !important;
}
.days {
  width: 1105px !important;
}



}
@media (min-width:1601px) and (max-width:1700x){
  .ground table tr td {
    font-size: 15px !important;

    font-weight: 400 !important;
  }

  .profile-pic4 img{
  height: 148px!important;
  width: 100% !important;
  }

  .login-input {
    margin-bottom: 15px;
  }
  .login-text{
    padding: 6rem 4rem !important;
    border-right: 1px solid #d0d9cd !important;
    height: 100% !important;
  }
  .login-input .mdc-text-field--filled {
    height: 50px !important;
  }
  .login-input .mat-mdc-form-field-infix {
    padding-top: 11px !important;
    padding-bottom: 16px !important;
  }
  .login-input input {
    font-size: 16px !important;
    color: #313131;
  }
  .login-input label {
    margin-bottom: 4px;
    font-size: 13px !important;
    color: #818181;
    font-weight: 700;
  }
  .mat-mdc-form-field-icon-suffix .my-show {
    font-size: 14px !important;
    margin-top: 7px !important;
    margin-bottom: 16px !important;
    margin-right: 14px !important;
    color: #91CC02 !important;
  }
  .forget p {
    margin-bottom: 25px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .sign p {
    margin-top: 1rem;
    font-size: 14px !important;
    font-weight: 700;
  }
  .login-text h4 {
    font-size: 25px !important;
    color: #333333;
    font-weight: 700;
    font-family: montserrat;
  }
  .login-text p {
    font-weight: 600;
    font-size: 16px !important;
    line-height: 25px !important;
  }
  .login-input input{
    font-size: 15px !important;
    color: #313131;
  }
  .login-input input::placeholder{
    font-size: 15px !important;
  }
  .login-btn{
    margin-top: 1.75rem !important;
    padding: 12px 28px !important;
    font-size: 14px !important;
  }
  .sports-img {
    width: 75% !important;
    display: block !important;
    margin: 6rem auto !important;
  }
  /* .login-box{
    height: 500px !important;
  } */


  .ground table tr  td {
    font-size: 12px;
    height: 38px !important;
}
}
@media (min-width:1701px) and (max-width:1900px){
  .noborder {
    border: none !important;
    margin-top: 7px !important;
}
.timeslot {
  background-color: #00c09d;
  width: auto;
  height: 43px !important;
  color: white;
  padding: 11px 2px 2px 2px !important;
  margin-top: 5px !important;
  font-size: 14px !important;
  border-radius: 3px;
  vertical-align: center;
  text-align: center;
}
.days {
  width: 1400px !important;
}
.day {
  width: 217px !important;

}



  .ground table tr td {
    font-size: 15px !important;

    font-weight: 400 !important;
  }


.view-box ul li i{
  font-size: 23px !important;
}
.view-box ul li  p{
  font-size: 17px !important;
}

  .inner-text {
    height: 47px !important;
    background-color: #EBEBEB;
    border-radius: 25px;
    border: 0.5px solid #647882;
}
.inner-text p{
  padding: 0.8rem !important;
  font-size: 15px;
  color: #313131;
}



  .login-box-admin{

    height: 31vh !important;
  }
  .profile-pic4 img {
    height: 140px !important;
    width: 100% !important;
  }
  .text-mid p{
    margin-bottom: 7px !important;

  }

  .login-input {
    margin-bottom: 15px;
  }
  .login-text{
    padding: 4rem 4rem !important;
  }
  .login-input .mdc-text-field--filled {
    height: 50px !important;
  }
  .login-input .mat-mdc-form-field-infix {
    padding-top: 11px !important;
    padding-bottom: 16px !important;
  }
  .login-input input {
    font-size: 16px !important;
    color: #313131;
  }
  .login-input label {
    margin-bottom: 4px;
    font-size: 13px !important;
    color: #818181;
    font-weight: 700;
  }
  .mat-mdc-form-field-icon-suffix .my-show {
    font-size: 14px !important;
    margin-top: 7px !important;
    margin-bottom: 16px !important;
    margin-right: 14px !important;
    color: #91CC02 !important;
  }
  .forget p {
    margin-bottom: 25px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .sign p {
    margin-top: 1rem;
    font-size: 14px !important;
    font-weight: 700;
  }
  .login-text h4 {
    font-size: 25px !important;
    color: #333333;
    font-weight: 700;
    font-family: montserrat;
  }
  .login-text p {
    font-weight: 600;
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .login-input input{
    font-size: 15px !important;
    color: #313131;
  }
  .login-input input::placeholder{
    font-size: 15px !important;
  }
  .login-btn{
    margin-top: 1.75rem !important;
    padding: 12px 28px !important;
    font-size: 14px !important;
  }
  .sports-img {
    width: 75% !important;
    display: block !important;
    margin: 6rem auto !important;
  }
  /* .inner-content {
    overflow-y: scroll;
    height: 100vh !important;
  } */
  /* .login-box{
    height: 500px !important;
  } */
  .iti--allow-dropdown input, .iti--allow-dropdown input {
    padding-right: 6px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .iti--separate-dial-code .iti__selected-dial-code {
    font-size: 14px !important;
    margin-top: 3px !important;
}
.ground table tr  td {
  font-size: 12px;
  height: 38px !important;
}
.chrg-active {
  border: 2px solid #C8C8C8 !important;
  overflow: hidden;
  width: 290px;
}

.text-p    p {
  color: #7E7E7E;
  font-size: 18px !important;
}

}
@media (min-width:1900px) and (max-width:2300px){

  .ground table tr td {
    font-size: 15px !important;

    font-weight: 400 !important;
  }


.view-box ul li i{
  font-size: 23px !important;
}
.view-box ul li  p{
  font-size: 17px !important;
}
/*
  .scroll1 {

    height: 680px !important;
  } */


.login-box-admin{

    height: 29vh !important;
}
  .profile-pic4 img {
    height: 150px !important;
    width: 100% !important;
  }
  .login-text {
    padding: 6rem 4rem !important;
  }
  .login-text h4{
    font-size: 32px !important;
  }
  .welcome{
    font-size: 19px !important;
  }
  .login-text label{
    font-size: 15px !important;
  }
  .login-input .mdc-text-field--filled {
    height: 56px !important;
}
  .login-input input {
    font-size: 18px !important;
  }
  .login-input .mat-mdc-form-field-infix {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .mat-mdc-form-field-icon-suffix .my-show {
    font-size: 18px !important;
    margin-top: 5px !important;
    margin-right: 13px !important;
  }
  .forget p {
    font-size: 17px !important;
  }
  .sign p {
    font-size: 15px !important;
  }
  .sub-log .login-btn{
    font-size: 18px !important;
    padding: 14px !important;
  }
  .login-text p {
    font-size: 19px !important;
    line-height: 33px !important;
    margin: 24px 0px !important;
  }
  .login-box input::placeholder{
    font-size: 16px !important;
  }
  .login-input label{
    padding-bottom: 10px !important;
    font-size: 17px !important;
  }
  /* .inner-content {
    overflow-y: scroll;
    height: 100vh !important;
} */
.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
  font-size: 16px !important;
}
.iti--allow-dropdown input {
  padding-right: 6px !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.login-input {
  margin-bottom: 20px !important;
}
.iti--allow-dropdown input, .iti--allow-dropdown input {
  padding-right: 6px !important;
  padding-top: 17px !important;
  padding-bottom: 17px !important;
  border-radius: 50px !important;
  border: 1px solid #ccc;
}

}


/* .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__shadow {
  background: #284715 !important;
} */




.mat-mdc-tab-labels {
  align-items: center;
}

.tabs .mat-mdc-tab.mdc-tab {
  height: 42px;
}


.tabs .mat-mdc-tab.mdc-tab--active {
  height: 42px!important;
}


.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: #ffffff!important;
    /* background: #0d6efd!important; */

}


.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: #0d6efd!important;
  }
 



  .rtl-direction{
    direction: rtl;
  }


  .rtl-direction .timepicker{
    direction: ltr!important;
  }


  .rtl-direction .mat-mdc-form-field-infix{
    direction: rtl!important;
  }

  .rtl-direction .persentage {
    margin-right: 8px!important;
    margin-left: 8px!important;

    border-right: 1px solid #ACB5C2!important;
    border-left:none!important;

    padding-right: 10px!important;
    padding-left: 0px!important;
    color: #ACB5C2!important;
}

.rtl-direction .mat-mdc-form-field{
  direction: rtl!important;
}

.rtl-direction .aed {
  margin-right: 0px!important;
  border-right: none!important;
  padding-right: 0px!important;
  padding-left: 6px!important;
  border-left: 1px solid #ACB5C2;
  margin-left: 5px!important;
}

/* .tabs .mat-mdc-tab.mdc-tab:last-child {
  border-radius: 0px 8px 8px 0px !important;
}


.rtl-direction .tabs .mat-mdc-tab.mdc-tab:nth-child(1) {
  border-radius: 0px 8px 8px 0px !important;
}



.rtl-direction .tabs .mat-mdc-tab.mdc-tab:last-child {
  border-radius: 0px 8px 8px 0px !important;
} */


.rtl-direction .tabs .mat-mdc-tab.mdc-tab:first-child{
  border: 1px solid #91cc02!important;
  border-radius: 0px 8px 8px 0px!important;
}

.tabs .mat-mdc-tab.mdc-tab:last-child {
  border: 1px solid #91cc02!important;
  border-radius: 0px 8px 8px 0px!important;
}


.rtl-direction .tabs .mat-mdc-tab.mdc-tab:last-child {
  border: 1px solid #91cc02 !important;
  border-radius: 8px 0px 0px 8px !important;
}

.tabs .mat-mdc-tab.mdc-tab:first-child{
  border: 1px solid #91cc02!important;
  border-radius: 8px 0px 0px 8px!important;
}

/* .row-reverse{
  flex-direction: row-reverse!important;
} */


.mat-drawer {
  overflow-y: auto;
  overflow-x: hidden;
}


.rtl-direction .aside img{
    margin: 6px 20px!important;

}
  
.rtl-direction .iti__country-list {
  position: absolute;
  z-index: 2;
  left: 0px;
  list-style: none;
  text-align: right;
}


.rtl-direction .mat-mdc-tab-body.mat-mdc-tab-body-active {
  margin: 0px 30px!important;
}